import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "menu",
    component: () => import("@/views/Menu.vue")
  }
];
const pages = require("@/data.json");
for (let i in pages) {
  const page = pages[i];
  if (!page.url) {
    routes.push({
      path: `/${page.id}`,
      name: page.id,
      component: () => import("@/views/Menu.vue")
    });
  }
}

routes.push({
  path: "/*",
  name: "menu",
  component: () => import("@/views/Menu.vue")
});

const router = new VueRouter({
  routes
});

export default router;
