import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VuePlyr from "./plugins/vue-plyr/";
import { VuePlyrControls } from "./plugins/vue-plyr/config";
import vuetify from "./plugins/vuetify";
import analytics from "./services/analytics";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.prototype.$hub = new Vue();
Vue.config.productionTip = false;

console.log("v1.5.9");

Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: true },
    settings: [],
    controls: VuePlyrControls
  },
  emit: ["ended"]
});

Vue.prototype.analytics = analytics;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
