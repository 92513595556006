// import { ga4_id } from "../../../config.json";

let ready;

export default {
  init,
  page,
  tab,
  slide,
  video
};

function init() {
  console.log("init");
}

function page(retina_page_name) {
  track("retina_page_view", { retina_page_name });
}

function tab(retina_tab_name) {
  retina_tab_name = retina_tab_name.split("&ndash;").join("-");
  track("retina_tab_view", { retina_tab_name });
}

function slide(retina_slideshow, retina_slide_num) {
  track("retina_slide", { retina_slideshow, retina_slide_num });
}

function video(retina_video, retina_video_state, retina_video_time) {
  track("retina_video", {
    retina_video,
    retina_video_state,
    retina_video_time
  });
}

function track(event, obj) {
  console.log("track", event, obj);
  if (ready) {
    // console.log("posting");
    // gtag("event", event, obj);
  } else {
    // console.log("not ready");
    // setTimeout(() => {
    //   track(event, obj);
    // }, 1000);
  }
}
